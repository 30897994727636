

































import Vue from 'vue'
import { actions } from '@/store/config'

export default Vue.extend({
  name: 'Login',
  data: function () {
    return {
        username: '',
        password: ''
    }
  },
  methods: {
      login: function () {
        const isAuthenticated = this.username === "bp" && this.password === "st";
        actions.setAuthenticated(isAuthenticated)

        if (isAuthenticated) {
           this.$router.push('/')
        }
      }
  }
})
